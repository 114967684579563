<!--  -->
<template>
  <div class="content">
    <h1>Content</h1>
    <p  class="choosep">Choose a form <span>*</span></p>
    <div  class="selectsearch" ref="selectBox"> 
        <input class="el-select" @click="selectclick"  ref="elselected"  v-model="radioValue" >
        <div v-show="selectedshow" class="el-options">
            <div class="inputop">
                <el-input
                class="search-text"
                v-model="searchValue"
                placeholder="Search"
                @keyup.enter.native="searchList"
                suffix-icon="el-icon-search"
                ></el-input>
            </div>
            <ul class="inputul">
                <li v-for="(item,num) in list" :key="num" @click="radioliclick(item)">
                    {{item}}
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
        radioVal: {    // 选择文件
            type: String,
            default:"" 
        },
    },
  data() {
    return {
        searchValue:'',
        ulshow:false,
        list:['aaede','efeg','rvrevr','ttt','q','w','e','r'],
        selectedshow:false,
        radioValue: this.radioVal,

    }
  },
  watch:{
    radioValue(){
      this.radiosearch()
    },
    radioVal(val){
      this.radioValue=val;
    }
  },
  mounted(){
    document.addEventListener("click", this.clickFn);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickFn);
  },
  methods:{
    clickFn(e) {
      // 记得在.select-box那边加上ref="selectBox"
      let selectBox = this.$refs.selectBox;
      // 重点来了：selectBox里是否包含点击的元素，不包含点击的元素就隐藏面板
      if(selectBox){
        if (!selectBox.contains(e.target)) {
          this.selectedshow = false;
        }
      }
    },
     searchList(){
       this.ulshow=false;
     },
      selectclick() {
        this.selectedshow=!this.selectedshow;
      }, 
      radioliclick(item){
        this.radioValue=item;
        this.selectedshow= false;
      },
      radiosearch(){
         if(this.radioValue==''){
        let obj={
          required:'Form',
          show:true,
        }
        this.$emit('formdata',obj)
      }else{
         let obj={
          required:'Form',
          show:false,
        }
        this.$emit('formdata',obj)
      }
      }
  },
   created(){
     this.radiosearch();
    },
}
</script>

<style lang='scss' scoped >
.content{
    width: 100%;
    min-height:72vh;
    background: #FFFFFF;
    border: 1px solid #DEDCDA;
    border-radius: 0px 0px 3px 3px;
    h1{
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #080707;
        letter-spacing: 0;
        margin-top: 39px;
        margin-left: 14px;
        margin-bottom: 24px;
    }
    .choosep{
        font-family: PingFangSC-Semibold;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        margin-bottom: 5px;
        margin-left: 14px;
        span{
            color: red;
            display: inline-block;
            width: 16px;
            height: 16px;
        }
    }
    .selectsearch{
        width: 294px;
        height: 40px;
        border: 1px solid #ccc;
        position: relative;
        margin-top: 10px;
        margin-left: 14px;
        .el-select{
            outline: none;
            width: 100%;
            height: 100%;
             border: 1px solid #DDDBDA;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;
        }
        .el-options{
           height: 307px;
            border: 1px solid #ccc;
            background: #ccc;
            width: 100%;
            position: absolute;
            left: 0;
            top: 50px; 
            z-index: 9999;
        }
        .inputop{
            height: 50px;
            width: 96%;
            margin-left: 2%;
            margin-top: 5px;
        }
        .inputul{
            width: 100%;
            height: 250px;
            background: #fff;
            overflow: auto;
            li{
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 5px;
                width: 100%;
                margin-bottom: 5px; 
            }
            li:hover{
                background: #ccc;
                cursor: pointer;
            }
        }
    }
  
}
</style>
