<!-- settings -->
<template>
  <div class="setting">
    <h1>Settings</h1>
    <!-- name -->
    <p>
      Internal page name <span>*</span>
      <el-tooltip
        class="item"
        effect="dark"
        :content="tooltip1"
        placement="top"
      >
        <svg class="icon" aria-hidden="true">
          <use href="#icon-prompt"></use>
        </svg>
      </el-tooltip>
    </p>
    <el-input
      type="text"
      v-model="nameval"
      class="inputtext"
      @keyup.enter.native="nameinput"
      @blur="nameinput"
    ></el-input>
    <h6 class="alert" v-if="nameshow">
      An internal page name is required to publish your page
    </h6>
    <!-- title -->
    <p>
      Page title <span>*</span>
      <el-tooltip
        class="item"
        effect="dark"
        :content="tooltip2"
        placement="top"
      >
        <svg class="icon" aria-hidden="true">
          <use href="#icon-prompt"></use>
        </svg>
      </el-tooltip>
    </p>
    <el-input
      type="text"
      v-model="title"
      class="inputtext"
      @keyup.enter.native="titleinput"
      @blur="titleinput"
    ></el-input>
    <h6 class="alert" v-if="titleshow">
      A page title is required to publish your page
    </h6>
    <h6 class="great" v-if="titshow">
      <span></span> Great！You're within the limit. {{ titlenumber }} characters
      remaining
    </h6>
    <!-- url -->
    <p>
      Page URL <span>*</span>
      <el-tooltip
        class="item"
        effect="dark"
        :content="tooltip3"
        placement="top"
      >
        <svg class="icon" aria-hidden="true">
          <use href="#icon-prompt"></use>
        </svg>
      </el-tooltip>
    </p>
    <div class="url">
      <div class="urlleft">
        <h5>Domains</h5>
        <el-select v-model="Domain" placeholder="">
          <el-option
            v-for="item in urls"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="urlright">
        <h5>Content slug</h5>
        <el-input
          v-model="slug"
          @keyup.enter.native="sluginput"
          @blur="sluginput"
        ></el-input>
      </div>
    </div>
    <h6 class="alert" v-if="Domainshow">The domain needs to be configured</h6>
    <h4 class="copy">
      <span @click="copyText(Domain + slug)">Copy link</span>
    </h4>
    <!-- Meta description -->
    <p>
      Meta description
      <el-tooltip
        class="item"
        effect="dark"
        :content="tooltip4"
        placement="top"
      >
        <svg class="icon" aria-hidden="true">
          <use href="#icon-prompt"></use>
        </svg>
      </el-tooltip>
    </p>
    <textarea v-model="textarea"> </textarea>
    <!-- Campaign -->
    <p>
      <!-- Campaign -->
      {{ $t("label.marketingactivity") }}

      <el-tooltip
        class="item"
        effect="dark"
        :content="tooltip5"
        placement="top"
      >
        <svg class="icon" aria-hidden="true">
          <use href="#icon-prompt"></use>
        </svg>
      </el-tooltip>
    </p>
    <div class="slected">
      <el-select v-model="campaign" placeholder="">
        <el-option
          v-for="item in campaigns"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { copyText } from "@/utils/copyInfo";
export default {
  props: {
    name: {
      // name
      type: String,
      default: "",
    },
    titled: {
      // title
      type: String,
      default: "",
    },
    slugg: {
      // slug
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tooltip1: "Internal name used for organization",
      nameshow: false,
      nameval: this.name,
      tooltip2: "Appears in the browser title bar and in search results",
      titleshow: false,
      titshow: false,
      titlenumber: null,
      title: this.titled,
      tooltip3: "Use only a '/' to publish to the home page",
      urls: [window.Glod.LOGIN_URL],
      Domain: "",
      Domainshow: false,
      slug: this.slugg,
      tooltip4:
        "A good meta description will drive search engine traffic to your page, so be descriptive but keep it under 155 characters.",
      textarea: "",
      tooltip5: this.$i18n.t("c620"),
      campaign: "Select a campaign",
      campaigns: ["Select a campaign"],
    };
  },
  created() {
    this.nameinput();
    this.titleinput();
    this.domainselect();
    this.sluginput();
  },
  watch: {
    Domain() {
      this.domainselect();
    },
    name(val) {
      this.nameval = val;
    },
    titled(val) {
      this.title = val;
    },
    slugg(val) {
      this.slug = val;
    },
  },
  methods: {
    copyText,
    nameinput() {
      if (this.nameval == "") {
        this.nameshow = true;
        let obj = {
          required: "Internal page name",
          show: true,
        };
        this.$emit("formdata", obj);
      } else {
        this.$emit("changename", this.nameval);
        this.nameshow = false;
        let obj = {
          required: "Internal page name",
          show: false,
        };
        this.$emit("formdata", obj);
      }
    },
    titleinput() {
      if (this.title == "") {
        this.titleshow = true;
        this.titshow = false;
        let obj = {
          required: "Page title",
          show: true,
        };
        this.$emit("formdata", obj);
      } else {
        this.titleshow = false;
        this.titshow = true;
        this.titlenumber = 70 - this.title.trim().length;
        let obj = {
          required: "Page title",
          show: false,
        };
        this.$emit("formdata", obj);
      }
    },
    domainselect() {
      if (this.Domain == "") {
        if (this.urls.length < 1) {
          this.Domainshow = true;
        } else {
          this.Domain = this.urls[0];
        }
      }
    },
    sluginput() {
      if (this.slug == "") {
        this.Domainshow = true;
        let obj = {
          required: "Page URL",
          show: true,
        };
        this.$emit("formdata", obj);
      } else {
        this.Domainshow = false;
        let obj = {
          required: "Page URL",
          show: false,
        };
        this.$emit("formdata", obj);
      }
    },
  },
};
</script>

<style lang='scss' scoped >
.icon {
  display: inline-block;
}
.setting {
  width: 100%;
  min-height: 73vh;
  background: #ffffff;
  border: 1px solid #dedcda;
  border-radius: 0px 0px 3px 3px;
  h1 {
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #080707;
    letter-spacing: 0;
    margin-top: 39px;
    margin-left: 14px;
  }
  ::v-deep .el-input__inner {
    outline: none;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 3px;
    padding-left: 10px;
    height: 50px;
  }
  p {
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    margin-bottom: 8px;
    margin-left: 14px;
    margin-top: 25px;
    span {
      color: red;
      display: inline-block;
      width: 16px;
      height: 16px;
      padding-right: 20px;
      padding-left: 5px;
    }
  }
  .inputtext {
    width: 622px;
    margin-left: 14px;
    margin-bottom: 5px;
  }
  .alert {
    font-family: PingFangSC-Semibold;
    font-size: 12px;
    color: #e02020;
    letter-spacing: 0;
    margin-left: 14px;
    margin-bottom: 25px;
  }
  .great {
    font-family: PingFangSC-Semibold;
    font-size: 12px;
    color: #333333;
    letter-spacing: 0;
    margin-left: 14px;
    margin-bottom: 25px;
    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #006dcc;
      margin-top: 3px;
    }
  }
  .url {
    width: 622px;
    display: flex;
    justify-content: space-between;
    margin-left: 14px;
    h5 {
      font-family: PingFangSC-Semibold;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
    }
    .urlleft {
      width: 450px;
      ::v-deep .el-select {
        width: 450px;
      }
    }
    .urlright {
      width: 162px;
    }
  }
  .copy {
    margin-left: 14px;
    margin-top: 5px;
    span {
      font-family: PingFangSC-Semibold;
      font-size: 12px;
      color: #007aff;
      letter-spacing: 0;
      cursor: pointer;
      font-weight: bold;
    }
  }
  textarea {
    width: 622px;
    min-height: 120px;
    outline: none;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 3px;
    margin-left: 14px;
    resize: none;
    padding-left: 15px;
  }
  .slected {
    width: 622px;
    margin-left: 14px;
    margin-bottom: 20px;
    ::v-deep .el-select {
      width: 622px;
    }
  }
}
</style>
