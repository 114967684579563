<!--  -->
<template>
  <div class="top">
    <h1>Settings</h1>
    <!-- 选择设置项 -->
    <el-radio v-model="radio" label="1">Redirect to another page</el-radio>
    <el-radio v-model="radio" label="2">Redirect to another page</el-radio>
    <div class="content">
      <span class="tooltip" v-show="radio === '1'"
        >Your landing page will be published immediately.</span
      >
      <div class="time_box" v-show="radio === '2'">
        <div class="tooltip">
          Update the future time and date to publish your landing page.
        </div>
        <div class="tooltip">When would you like to release your page?</div>

        <el-dropdown trigger="click">
          <div class="input_box">
            <el-input
              placeholder=""
              prefix-icon="el-icon-date"
              v-model="dateValue"
              size="mini"
              class="timeInput"
              :readonly="true"
              @focus="focusInput"
            >
            </el-input>
            <el-input
              placeholder=""
              v-model="timeValue"
              size="mini"
              class="timeInput"
              :readonly="true"
              @focus="focusInput"
            >
            </el-input>
            <span>(UTC)</span>
          </div>
          <!-- 时间选择 -->
          <el-dropdown-menu slot="dropdown" placement="bottom-end">
            <div class="customTime" v-show="customFlg">
              <div class="dateView">
              </div>
              <div class="selectDate" v-show="timeFlg">
                <span>Custom publish time</span>
                <div class="inputTime">
                  <el-input
                    v-model="timeSelect"
                    size="mini"
                    style="width: 180px"
                    type="time"
                  ></el-input>
                  <el-button size="mini" type="primary" @click="fillTime"
                    >Okay</el-button
                  >
                </div>
                <a @click="timeFlg = !timeFlg">
                  Show default publishing times</a
                >
              </div>
              <div class="showDate" v-show="!timeFlg">
                <span>Custom publish time</span>
                <el-button size="mini" class="nowBt" @click="publishNow"
                  >Publish now</el-button
                >
                <div class="selectBt">
                  <el-button
                    size="mini"
                    class="timeBt"
                    v-for="(item, index) in showBtList"
                    :key="index"
                    @click="selectTime(item)"
                    >{{ item }}</el-button
                  >
                </div>
                <a @click="timeFlg = !timeFlg">Show default publishing times</a>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      customFlg: false,
      showBtList: ['20:01 PM', '20:01 PM', '20:01 PM', '20:01 PM', '20:01 PM', '20:01 PM'],
      timeSelect: new Date(),
      timeFlg: false,
      fromViewDate: "2021-07-03",
      radio: '1',
      dateValue: '',
      timeValue: ''
    };
  },
  created() {
    let dd = new Date();
    dd.setDate(dd.getDate() + 1)
    let y = dd.getFullYear();
    let m =
      dd.getMonth() + 1 < 10
        ? '0' + (dd.getMonth() + 1)
        : dd.getMonth() + 1;
    let d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
    this.fromViewDate = y + '-' + m + '-' + d;
  },
  //方法集合
  methods: {
    fillTime() {
      this.timeValue = this.timeSelect
    },
    selectTime(value) {
      this.timeValue = value
    },
    // 当前时间
    publishNow() {
      this.dateValue = 'Publish now'
      this.timeValue = ''
    },
    focusInput() {
      this.customFlg = true
    },
  },
}
</script>
<style lang='scss' scoped>
.top {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  border: 1px solid #dedcda;
  border-radius: 0px 0px 3px 3px;
  padding: 15px;
  h1 {
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #080707;
    letter-spacing: 0;
    margin-top: 20px;
  }
}
.customTime {
  width: 590px;
  z-index: 10;
  background-color: #ffffff;
  .dateView {
    float: left;
    width: 295px;
  }
  .selectDate {
    margin-left: 295px;
    padding: 20px;

    .inputTime {
      display: flex;
      justify-content: space-between;
    }
    span {
      height: 30px;
      line-height: 30px;
      font-family: Helvetica;
      font-size: 14px;
      color: #3e3c3c;
      letter-spacing: 0;
      font-weight: bold;
    }
    a {
      display: block;
      height: 40px;
      line-height: 40px;
      font-family: Helvetica;
      font-size: 14px;
      color: #006dcc;
      letter-spacing: 0;
    }
  }
  .showDate {
    margin-left: 295px;
    padding: 20px;
    .selectBt {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      .timeBt {
        font-family: MicrosoftYaHei;
        font-size: 10px;
        color: #3e3c3c;
        margin: 5px 0px;
      }
    }
    .nowBt {
      width: 100%;
      margin-bottom: 20px;
    }
    span {
      height: 30px;
      line-height: 30px;
      font-family: Helvetica;
      font-size: 14px;
      color: #3e3c3c;
      letter-spacing: 0;
      font-weight: bold;
    }
    a {
      display: block;
      height: 40px;
      line-height: 40px;
      font-family: Helvetica;
      font-size: 14px;
      color: #006dcc;
      letter-spacing: 0;
    }
  }
}
.input_box {
  display: flex;
  justify-content: space-between;
  width: 500px;
  .timeInput {
    width: 210px;
  }
  span {
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
  }
}
.content {
  .tooltip {
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #3e3c3c;
    height: 60px;
    line-height: 60px;
  }
}
</style>