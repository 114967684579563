<!-- created -->
<template>
  <div class="created">
    <div class="header">
      <div class="headerup">
        <div class="headerupleft">
          <el-button size="mini" @click="exitclick">Exit</el-button>
          <el-button size="mini" @click="saveclick">Save</el-button>
        </div>
        <div class="headerupcenter" ref="headerupcenter">
          <input v-model="headline" v-show="editLineFlg" />
          {{ editLineFlg ? "" : headline }}
          <svg class="icon" aria-hidden="true" @click="editLine">
            <use href="#icon-icon-editName"></use>
          </svg>
        </div>
        <div class="headerupright">
          <el-badge :value="badgenum" class="item" :hidden="badgenum < 1">
            <el-popover
              placement="bottom"
              width="200"
              trigger="hover"
              popover-class="popoverrequired"
            >
              <p
                class="warn"
                style="color: #080707; font-weight: bold"
                v-if="badgenum > 0"
              >
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-warning_round"></use>
                </svg>
                Required
              </p>
              <ul class="reauiredui" v-if="badgenum > 0">
                <li v-for="(item, index) in badges" :key="index">
                  {{ index + 1 }}、
                  <span
                    style="color: #409eff; cursor: pointer; font-weight: bold"
                    @click="requiredclick(item)"
                    >{{ item }}</span
                  >
                  is required
                </li>
              </ul>
              <span v-if="badgenum < 1">No required</span>
              <el-button
                size="mini"
                type="primary"
                slot="reference"
                @click="publishclick"
                >Publish</el-button
              >
            </el-popover>
          </el-badge>
        </div>
      </div>
      <div class="headerdown">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item
            v-for="(item, index) in tablist"
            :key="index"
            :index="item"
            >{{ item }}</el-menu-item
          >
        </el-menu>
      </div>
      <creat-Content
        v-show="activeIndex == 'Content'"
        @formdata="formdata"
        :radioVal="radioVal"
      >
      </creat-Content>
      <setting
        v-show="activeIndex == 'Settings'"
        @formdata="formdata"
        :name.sync="name"
        :titled="titled"
        :slugg="slugg"
        @changename="changename"
      ></setting>
      <schedule v-show="activeIndex == 'Schedule'"></schedule>
    </div>
  </div>
</template>

<script>
import creatContent from "@/views/landingPage/created/components/content.vue";
import setting from "@/views/landingPage/created/components/settings.vue";
import schedule from "@/views/landingPage/created/components/schedule.vue";
export default {
  components: {
    creatContent,
    setting,
    schedule,
  },
  data() {
    return {
      editLineFlg: false,
      headline: '',
      activeIndex: 'Content',
      tablist: ['Content', 'Settings', 'Schedule'],
      badgenum: null,
      badges: [],
      object: {},
      //必填项
      radioVal: '',
      name: '',
      titled: '',
      slugg: '',
    }
  },
  watch: {
    badges() {
      this.badgenum = this.badges.length;
      this.requireddata();
    }
  },
  created() {
    this.badgenum = this.badges.length;
    if (this.$route.params.id) {
      this.headline = this.$route.params.id;
      this.radioVal = 'aaaaaa';
      this.name = this.headline;
      this.titled = '阿多根';
      this.slugg = '/len/hao/ya';
    } else {
      this.headline = this.$route.params.name;
      this.name = this.headline;
    }

  },
  mounted() {
    document.addEventListener("click", this.clickFn);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickFn);
  },
  methods: {
    clickFn(e) {
      // 记得在.select-box那边加上ref="selectBox"
      let headerupcenter = this.$refs.headerupcenter;
      // 重点来了：selectBox里是否包含点击的元素，不包含点击的元素就隐藏面板
      if (headerupcenter) {
        if (!headerupcenter.contains(e.target)) {
          this.editLineFlg = false;
          this.name = this.headline;
        }
      }
    },
    editLine() {
      this.editLineFlg = !this.editLineFlg;
      this.name = this.headline;
    },
    handleSelect(key) {
      this.activeIndex = key;
    },
    formdata(arr) {
      this.object = arr;
      this.requireddata();
    },
    requireddata() {
      if (this.object.show == true) {

        if (!this.badges.includes(this.object.required)) {
          this.badges.push(this.object.required)
        }

      }
      if (this.object.show == false) {
        if (this.badges.includes(this.object.required)) {
          let inde = this.badges.indexOf(this.object.required);
          this.badges.splice(inde, 1)
        }
      }
    },
    requiredclick(item) {
      if (item == "Form") {
        this.activeIndex = 'Content';
      } else {
        this.activeIndex = 'Settings';
      }
    },
    changename(name) {
      this.headline = name;
    },
    exitclick() {
      this.$router.go(-1)
    },
    saveclick() {
      this.$router.go(-1)
    },
    publishclick() {
      if (this.badgenum > 0) {
        this.$message({
          showClose: true,
          message: `Have ${this.badgenum} required`,
          type: 'error'
        });

      }
    }
  },
}
</script>
<style lang='scss' scoped >
.icon {
  display: inline-block;
}
.header {
  width: 99%;
  height: 121px;
  background-color: azure;
  margin: 10px auto;
  .headerup {
    height: 60px;
    background: #f5f5f5;
    border: 1px solid #dddbda;
    border-bottom: none;
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px 0 20px;
    .headerupleft {
      width: 30%;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      cursor: pointer;
      img {
        width: 15.6px;
        height: 15.6px;
        margin-top: -3px;
        cursor: pointer;
      }
    }
    .headerupcenter {
      width: 30%;
      text-align: center;
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #080707;
      letter-spacing: 0;
      img {
        width: 15.1px;
        height: 15.2px;
        cursor: pointer;
      }
    }
    .headerupright {
      width: 30%;
      text-align: right;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;

      .warn {
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #080707;
        font-weight: bold;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .requiredui {
        li {
          span {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #409eff;
            letter-spacing: 0;
          }
        }
      }
    }
  }
  .headerdown {
    width: 100%;
    height: 61px;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 0 0 3px 3px;
    display: flex;
    justify-content: center;
    ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: 4px solid #409eff;
      color: #303133;
    }
    ::v-deep .el-menu--horizontal > .el-menu-item {
      height: 58px;
    }
  }
}
</style>
